/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import MyCloudApi from 'mewe/api/mycloud-api';
import Scrolling from 'mewe/utils/scrolling-utils';
import { A } from '@ember/array';
import { action } from '@ember/object';
import { sortMediaOptions } from 'mewe/utils/options';

export default class MwAudioFeed extends Component {
  @tracked sorting;
  @tracked sortOptions;
  @tracked audios = A([]);
  @tracked isLoading = false;
  @tracked nextPageUrl = null;

  scrolling = Scrolling();


  constructor() {
    super(...arguments);

    this.sortOptions = sortMediaOptions({ bySize: true });
    this.sorting = this.sortOptions.subOptions[0];

    this.scrolling.bindScrollDown(() => this.fetchAudios());
    this.fetchAudios();
  }

  willDestroy() {
    this.scrolling.unbindScrollDown();
  }

  resetList() {
    this.audios = A([]);
    this.nextPageUrl = null;
    this.fetchAudios();
  }

  @action
  removeAudio(audio) {
    this.audios = this.audios.filter((a) => a.id !== audio.id);
  }

  @action
  orderMedia(option) {
    this.sorting = option;
    this.resetList();
  }

  async fetchAudios() {
    this.isLoading = true;

    let params = {
      limit: 10,
      orderBy: this.sorting.orderBy,
      orderAsc: this.sorting.orderAsc,
    };

    let audios;
    if (this.nextPageUrl) {
      audios = await MyCloudApi.getAudioFeeds({}, this.nextPageUrl);
      this.audios.pushObjects(audios.feed);
    } else {
      audios = await MyCloudApi.getAudioFeeds(params);
      this.audios = A(audios.feed);
    }
    this.nextPageUrl = audios._links?.nextPage?.href;
    this.isLoading = false;

    if (this.nextPageUrl) {
      this.scrolling.bindScrollDown(() => this.fetchAudios());
    } else {
      this.scrolling.unbindScrollDown();
    }
  }
}
