import ApiUtil from './api';

class Api extends ApiUtil {
  constructor() {
    super();

    this.unsubscribe = {
      groupInvitation: (id) => {
        return this.post('/account/group-invite-unsubscribe/' + id);
      },

      contactInvitation: (id) => {
        return this.post('/account/contact-invite-unsubscribe/' + id);
      },

      newsletter: (id) => {
        return this.post('/account/newsletter-unsubscribe/' + id);
      },

      emailNotifications: (id) => {
        return this.post('/account/notifications-unsubscribe/' + id);
      },
    };
  }

  contact(publicId) {
    return this.get(`/public/${publicId}`, null, 'apiCacheRoot');
  }

  postsfeed(publicId) {
    return this.get(`/home/user/${publicId}/postsfeed/public`, null, 'apiCacheRoot');
  }

  group(publicId) {
    return this.get('/group/public/' + publicId);
  }

  groupById(groupId) {
    return this.get('/group/publicById/' + groupId);
  }

  getEvent(publicId) {
    return this.get('/event2/public/' + publicId);
  }

  getEventById(eventId) {
    return this.get('/event2/publicById/' + eventId);
  }

  getPage(publicId) {
    return this.get(`/pages/page/${publicId}/public`);
  }

  getPagePublicPostsFeed(uuid, params) {
    return this.getNextPageOr(`/pages/page/${uuid}/postsfeed`, params, 'apiCacheRoot');
  }

  getPageHashTags(params, pageId) {
    const headers = { data: params };
    return this.get(`/pages/page/${pageId}/hashtags`, headers, 'apiCacheRoot');
  }

  checkEmailAvailability(email) {
    return this.get('/auth/checkEmail?email=' + encodeURIComponent(email.toLowerCase()));
  }

  validatePhone(params, traceId = '') {
    return this.post('/validation/phone/send', {
      data: JSON.stringify(params),
      traceId,
    });
  }

  getSiwa(params) {
    return this.get('/auth/dsnp/siwa/request', {
      data: params,
    });
  }

  postSiwa(params, traceId = '') {
    return this.post('/auth/dsnp/siwa/signin', {
      data: JSON.stringify(params),
      traceId,
    }, 'apiRoot3');
  }

  siwaFallbackLogin(params, options = {}) {
    return this.post('/auth/dsnp/siwa/fallback/login', {
      data: params,
      non401: true,
      traceId: options.traceId,
      contentType: 'application/x-www-form-urlencoded',
    });
  }

  registerAccount(params, options = {}) {
    return this.post(
      '/auth/register',
      {
        data: JSON.stringify(params),
        traceId: options.traceId,
        xDsnp: options.xDsnp,
      },
      'apiRoot3'
    );
  }

  inviteeData(mergeId) {
    return this.post(`/mycontacts/mail-invite-confirm/${mergeId}`);
  }

  checkAccountByEmail(email) {
    return this.get(`/auth/check/user/email?skipPayload=true&email=${encodeURIComponent(email.toLowerCase())}`);
  }

  checkAccountByPhone(code, phone) {
    return this.get(`/auth/check/user/phone?skipPayload=true&countryCode=${encodeURIComponent(code)}&phone=${phone}`);
  }

  authCheckPhone(code, phone) {
    return this.get(`/auth/checkPhone?countryCode=${encodeURIComponent(code)}&phoneNumber=${phone}`);
  }

  authCheckEmail(email) {
    return this.get(`/auth/checkEmail?email=${encodeURIComponent(email.toLowerCase())}`);
  }

  getEmailLoginPayload(params, traceId = '') {
    params.platform = 'web';
    return this.post(`/dsnp/login/payload/email`, {
      data: JSON.stringify(params),
      traceId,
    });
  }

  getPhoneLoginPayload(params, traceId = '') {
    params.platform = 'web';
    return this.post(`/dsnp/login/payload/phone`, {
      data: JSON.stringify(params),
      traceId,
    });
  }

  getEmailSignupPayload(params) {
    params.platform = 'web';
    return this.post(`/dsnp/signup/payload/email`, {
      data: JSON.stringify(params),
    });
  }

  getPhoneSignupPayload(params) {
    return this.post(`/dsnp/signup/payload/phone`, {
      data: JSON.stringify(params),
    });
  }

  getMigrationPayload(params) {
    return this.post(`/dsnp/migrate/payload`, {
      data: JSON.stringify(params),
    });
  }

  confirmMigration(params, traceId = '') {
    return this.post(`/dsnp/migrate`, {
      data: JSON.stringify(params),
      traceId,
    });
  }

  getRegistrationInfo() {
    return this.get(`/registration/info`);
  }
}

export default new Api();
