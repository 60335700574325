module.exports = {
  "apiRoot": "/api/v2",
  "apiRoot3": "/api/v3",
  "apiCacheRoot": "/api/cache/v2",
  "buildNumber": "4.165.0-2-e7801cd",
  "giphyApiKey": "S4tCV9IArCPwyUMj2a9r6DDq5sdWsigi",
  "mixpanelApiKey": "275673a98ed905e9f3a028f2ebcde00f",
  "mixpanelApiHost": "https://mp.wojtek2.ci.k8s.sgr-labs.com",
  "paypalClientId": "ATvdkBCQJBIvDqO9a_fYuCLHZddmwpUbubizSdum41KWRb7foaQRYi3W96Ww",
  "maxTextLimit": "63999",
  "maxMessageLimit": "8192",
  "maxFileLimit": "500",
  "chatMaxUsers": "100",
  "maxContacts": "80",
  "maxFavorites": "PLACEHOLDER_MAX_FAVORITES",
  "cdnHost": "https://cdn.wojtek2.ci.k8s.sgr-labs.com",
  "distDir": "target/frontend",
  "sseHost": "https://ws.wojtek2.ci.k8s.sgr-labs.com",
  "arkoseKeyReg": "F09AEBD2-0D74-A764-5E7D-CF380C6C3227",
  "arkoseKeyLog": "B7088B6B-1E93-E60F-64FA-06B69616F4D4",
  "apiHost": "https://wojtek2.ci.k8s.sgr-labs.com",
  "websocketsHost": "wss://ws.wojtek2.ci.k8s.sgr-labs.com",
  "cdnEmojiHost": "https://cdn.wojtek2.ci.k8s.sgr-labs.com",
  "cHost": "https://wojtek2.ci.k8s.sgr-labs.com",
  "imgHost": "https://img.wojtek2.ci.k8s.sgr-labs.com",
  "publicImgHost": "https://public-img.wojtek2.ci.k8s.sgr-labs.com",
  "matomo": "PLACEHOLDER_MATOMO",
  "configCatSDKKey": "5hTu19xMREmFtYLW3x61mA",
  "sentryDSN": "https://58373aadbe574ab597d5831b30650115@o62418.ingest.sentry.io/6261412",
  "sampleRate": "1",
  "tracesSampleRate": "0.05",
  "environment": "dev",
  "hcaptcha": {
    "lock": "31853e81-ec59-4ef4-9cd1-d38c1bff32b2",
    "registration": "4920eec0-e1bf-4b5a-8d11-b8e89ba77a9d",
    "login": "ffe42d11-251d-4e3e-bfbf-4ad501a6c96f",
    "password": "fc7d8a3d-1c86-4de7-8901-e34b5db61e22",
    "phoneValidation": "2bf43acd-b4cd-425b-8555-2e26674383cf"
  }
}
